import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, CircularProgress, IconButton, Card, CardContent } from '@mui/material';
import axios from 'axios';
import config from '../config';
import { calculateAge } from '../Utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const { BASE_URL } = config;

const AnalyzeWithAI = ({ healthGoals, selectedSupplements, userProfile, renderBelow, auth }) => {
  const [loadingAI, setLoadingAI] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [expanded, setExpanded] = useState(false); // State to manage the expansion
  const resultRef = useRef(null); // Reference for the result section

  const handleAnalyze = async () => {
    setLoadingAI(true); // Set loading state
    setAnalysisResult(null); // Clear previous analysis
    try {
      const token = await auth.currentUser.getIdToken();
      const age = calculateAge(userProfile.dateOfBirth);
      const response = await axios.post(`${BASE_URL}/analyze-stack`, {
        healthGoals,
        supplements: selectedSupplements.map(supplement => ({
          name: supplement.name,
          dosage: `${supplement.dosage} ${supplement.unit}`
        })),
        age,
        gender: userProfile.gender,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setAnalysisResult(response.data);
      setExpanded(true); // Automatically expand results after analysis
    } catch (error) {
      console.error('Error analyzing stack:', error);
    } finally {
      setLoadingAI(false); // Reset loading state
    }
  };

  useEffect(() => {
    if (analysisResult && expanded) {
      // Scroll to the results section when the cards have rendered
      resultRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [analysisResult, expanded]);

  const renderCards = (result) => {
    return Object.entries(result).map(([key, value]) => (
      <Card key={key} className="card">
        <CardContent sx={{ padding: '0px', marginBottom: 0 }}>  {/* Reduced padding */}
          <Typography variant="h6" className="card-heading" gutterBottom>
            {key.charAt(0).toUpperCase() + key.slice(1)}
          </Typography>
          {typeof value === 'object' ? (
            Object.entries(value).map(([subKey, subValue]) => (
              <Typography key={subKey} variant="body2" gutterBottom sx={{ fontSize: '1rem' }}>  {/* Slightly increased font size */}
                <strong>{subKey.charAt(0).toUpperCase() + subKey.slice(1)}:</strong> {subValue}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" gutterBottom sx={{ fontSize: '0.95rem' }}> {/* Slightly increased font size */}
              {value}
            </Typography>
          )}
        </CardContent>
      </Card>
    ));
  };

  return (
    <Box sx={{ width: '100%', marginTop: renderBelow ? 4 : 0 }}>
      {!renderBelow && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAnalyze}
          disabled={loadingAI}
          sx={{ marginBottom: 2, position: 'relative', minWidth: 150 }}
        >
          {loadingAI ? (
            <CircularProgress size={24} sx={{ color: 'white' }} />
          ) : (
            'Analyze with myStack AI'
          )}
        </Button>
      )}

      {analysisResult && (
        <Box ref={resultRef}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}>
            <Typography variant="h6">Analysis Results</Typography>
            <IconButton onClick={() => setExpanded(!expanded)}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          {expanded && renderCards(analysisResult)}
        </Box>
      )}
    </Box>
  );
};

export default AnalyzeWithAI;