import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs, doc, deleteDoc, updateDoc, getDoc } from 'firebase/firestore';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Button,
  Switch,
  TextField,
  Tooltip,
  Paper,
  Chip
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Share as ShareIcon, Close as CloseIcon, ContentCopy as CopyIcon } from '@mui/icons-material';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import PublicIcon from '@mui/icons-material/Public';
import CreateStackButton from './CreateStackButton';

function Dashboard() {
  const [userName, setUserName] = useState('');
  const [stacks, setStacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [supplementMap, setSupplementMap] = useState({});
  const [shareUrls, setShareUrls] = useState({});
  const [showShareField, setShowShareField] = useState({});

  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    async function fetchUserData() {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        // Fetch user data
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserName(userData.firstName || user.displayName || user.email);
        } else {
          console.log('No such document!');
        }

        // Fetch user stacks from Firestore
        const q = query(collection(db, 'stacks'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const stacksData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setStacks(stacksData);
        setLoading(false);
      }
    }

    fetchUserData();
  }, []);

  const handleEdit = (stackId) => {
    navigate(`/edit-stack/${stackId}`);
  };

  const handleRemove = async (stackId) => {
    if (window.confirm('Are you sure you want to delete this stack?')) {
      try {
        await deleteDoc(doc(db, 'stacks', stackId));
        setStacks(stacks.filter(stack => stack.id !== stackId));
      } catch (error) {
        console.error('Error deleting stack:', error);
      }
    }
  };

  const handleTogglePublic = async (stackId, currentStatus) => {
    try {
      const stackRef = doc(db, 'stacks', stackId);
      await updateDoc(stackRef, { isPublic: !currentStatus });
      setStacks(stacks.map(stack =>
        stack.id === stackId ? { ...stack, isPublic: !currentStatus } : stack
      ));
    } catch (error) {
      console.error('Error updating stack public status:', error);
    }
  };

  const handleShare = (stackId) => {
    const shareUrl = `${window.location.origin}/view-stack/${stackId}`;
    setShareUrls({
      ...shareUrls,
      [stackId]: shareUrl,
    });
    setShowShareField({
      ...showShareField,
      [stackId]: true,
    });
  };

  const handleShareFieldClose = (stackId) => {
    setShowShareField({
      ...showShareField,
      [stackId]: false,
    });
  };

  const handleCopyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
  };
  const noStacks = stacks.length === 0 && !loading;

  useEffect(() => {
    // Trigger the Google Ads conversion event when the Dashboard is loaded
    window.gtag('event', 'conversion', {
      'send_to': 'AW-16692189167/5TmOCMbY8c8ZEO-vupc-',
      'value': 1.0,
      'currency': 'USD'
    });
  }, []); // Empty dependency array ensures this runs only once on page load


  return (
    <Container component="main" maxWidth="lg" sx={{ padding: '0 16px', marginBottom: '50px'  }}>
      <Box sx={{ marginTop: 1, marginBottom: 8, display: 'flex', flexDirection: 'column', alignItems: noStacks ? 'center' : 'flex-start',
          justifyContent: noStacks ? 'center' : 'flex-start' }}>
        
        {noStacks ? (
          <Card
            sx={{
              padding: theme.spacing(4),
              textAlign: 'center',
              maxWidth: 600,
              marginTop: 5,
              boxShadow: theme.shadows[3],
              elevation: 9,
              borderRadius: theme.shape.borderRadius,
            }}
          >
            <CardContent>
              <Typography component="h4" variant="h4" gutterBottom>
                Hi {userName}!
              </Typography>
              <Typography variant="h4" gutterBottom
                sx={{ 
                  marginBottom: 1, 
                  letterSpacing: '0.5px', 
                  fontSize: '1.6rem', 
                  color: theme.palette.text.primary 
                }}
              >
                Boost your potential
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: 4 }}>
                Build a supplement stack with AI or add and analyze your current stacks
              </Typography>
              <CreateStackButton></CreateStackButton>
            </CardContent>
          </Card>
        ) : (
<div>
  <Typography component="h1" variant="h5" sx={{ marginBottom: 4}}>
    Hi {userName}!
  </Typography>
  <CreateStackButton></CreateStackButton>
</div>
        )
       
      }
      </Box>
      <Box>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          {stacks.map((stack) => (
            <Grid item xs={12} sm={6} md={4} key={stack.id}>
              <Card sx={{ boxShadow: 3, borderRadius: 3, '&:hover': { boxShadow: 6 }, transition: '0.3s', position: 'relative' }}>
                {stack.origin === 'AI' && (
                  <Chip
                    label="AI"
                    color="secondary"
                    size="small"
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                  />
                )}
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography component="h5" variant="h5" sx={{ marginLeft: 1 }}>{stack.name}</Typography>
                  </Box>
                  <Box sx={{ marginTop: 2 }}> 
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Supplement</TableCell>
                            <TableCell>Dosage</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stack.supplements.map((supplement, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {supplementMap[supplement.supplementId] || supplement.name}
                              </TableCell>
                              <TableCell>
                                {`${supplement.dosage} ${supplement.unit}`}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginTop: 1 }}>
                    <Switch
                          checked={stack.isPublic}
                          onChange={() => handleTogglePublic(stack.id, stack.isPublic)}
                          color="primary"
                          icon={<PublicIcon />}
                          fontSize="small"
                          checkedIcon={<PublicIcon fontSize="small" style={{ color: 'green' }} />}
                          marginbottom="0"
                        />
                      <Box display="flex" flexDirection="row" alignItems="center" marginbottom="0">
                        {stack.isPublic && (
                          <IconButton onClick={() => handleShare(stack.id)} aria-label="share" size='small'>
                            <ShareIcon fontSize="small" />
                          </IconButton>
                        )}
                        <IconButton aria-label="edit" onClick={() => handleEdit(stack.id)} size="small">
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={() => handleRemove(stack.id)} size="small">
                          <DeleteIcon fontSize="small" sx={{ color: theme.palette.grey[600] }} />
                        </IconButton>
                      </Box>
                    </Box>
                    {stack.isPublic && showShareField[stack.id] && (
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <TextField
                          value={shareUrls[stack.id]}
                          size="small"
                          fontSize="small"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            autoFocus: true,
                          }}
                          onFocus={(e) => e.target.select()}
                        />
                        <Tooltip title="Copy to clipboard">
                          <IconButton onClick={() => handleCopyToClipboard(shareUrls[stack.id])} sx={{ ml: 1 }}>
                            <CopyIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <IconButton onClick={() => handleShareFieldClose(stack.id)} sx={{ ml: 1 }}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

export default Dashboard;