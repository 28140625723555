import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function VerifyEmail() {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login');  // Adjust the path to your actual login route
  };

  return (
    <Container component="main" maxWidth="xs">
      <div style={{ marginTop: '20%', textAlign: 'center' }}>
        <Typography component="h1" variant="h5">
          Verify Your Email
        </Typography>
        <Typography variant="body1" style={{ marginTop: '20px' }}>
          We've sent a verification link to your email address. Please check your inbox and click the link to verify your email before logging in.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLoginRedirect}
          style={{ marginTop: '20px' }}
        >
          Go to Login
        </Button>
      </div>
    </Container>
  );
}

export default VerifyEmail;