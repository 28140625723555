const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const FIREBASE_STORAGE_BASE_URL_DEV = 'https://firebasestorage.googleapis.com/v0/b/stack-94b04.appspot.com/o/';
const FIREBASE_STORAGE_BASE_URL_PROD = 'https://firebasestorage.googleapis.com/v0/b/mystack-41374.appspot.com/o/';

const FIREBASE_STORAGE_BASE_URL = process.env.REACT_APP_ENVIRONMENT === 'production' ? FIREBASE_STORAGE_BASE_URL_PROD : FIREBASE_STORAGE_BASE_URL_DEV;

const UNITS = {
  mg: 'mg',
  mcg: 'mcg',
  g: 'g',
  IU: 'IU',
  ml: 'ml',
  fl_oz: 'fl oz',
  tbsp: 'tbsp',
  tsp: 'tsp',
  mcg_DFE: 'mcg DFE',
  mg_NE: 'mg NE',
  mcg_RAE: 'mcg RAE',
  mg_alpha_tocopherol: 'mg alpha-tocopherol',
  billion_CFU: 'billion CFU',
  tablet: 'Tablet',
  capsule: 'Capsule' /*,
  chewable_tablet: 'Chewable tablet',
  capsule: 'Capsule',
  softgel: 'Softgel',
  gelcap: 'Gelcap',
  caplet: 'Caplet',
  gummy: 'Gummy',
  powder: 'Powder',
  lozenge: 'Lozenge',
  bar: 'Bar',*/
};

export default {
  BASE_URL,
  FIREBASE_STORAGE_BASE_URL,
  UNITS,
};