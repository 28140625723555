import React from 'react';
import { Container, Box, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Box
          component="iframe"
          src="/privacy-policy.html"
          sx={{
            width: '100%',
            height: '80vh',
            border: 'none',
            mt: 2,
          }}
        />
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;