import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseDevConfig = {
  apiKey: "AIzaSyDsX2s0JQKz3WukKRdmTxToCO9fMJJi7AM",
  authDomain: "stack-94b04.firebaseapp.com",
  projectId: "stack-94b04",
  storageBucket: "stack-94b04.appspot.com",
  messagingSenderId: "272020500136",
  appId: "1:272020500136:web:5e82c8fa8adce1fd339c9d",
  measurementId: "G-60W2TJ3PHN"
};
  
const firebaseProdConfig = {
  apiKey: "AIzaSyANUonx4OoS3EWjXPdJGKvsopGLihCNT-c",
  authDomain: "my-stack.ai",
  projectId: "mystack-41374",
  storageBucket: "mystack-41374.appspot.com",
  messagingSenderId: "113313505883",
  appId: "1:113313505883:web:9c8ff100f2cebd814e957e",
  measurementId: "G-MTYN1ZE6T4"
};

const environment = process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV;

let config;
switch (environment) {
  case 'production':
    config = firebaseProdConfig;
    break;
  case 'staging':
    config = firebaseDevConfig;
    break;
  default:
    config = firebaseDevConfig;
    break;
}

const app = initializeApp(config);
const auth = getAuth(app);
const db = getFirestore(app);;
const analytics = getAnalytics(app);

export { auth, db };