import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Search from './Search';
import Detail from './Detail';
import { Card } from '@mui/material';

function Supplements() {
  const { supplementKey } = useParams(); // Get the supplement key from the URL if available
  const [searchFocused, setSearchFocused] = useState(false);

  useEffect(() => {
       setSearchFocused(!!supplementKey);
  }, [supplementKey]);

  const handleLoadingComplete = () => {
    setSearchFocused(false); // Reset search focused state after loading completes
  };


  return (
    <Card className={`supplements-page ${searchFocused ? 'top' : 'center'}`}>
      <Search 
        itemKey={supplementKey}
        dataKey="supplements"
        detailKey="card"
        routeBase="supplements"
        label="Search supplements"
      />
      {supplementKey && (
        <Detail 
          dataKey="supplements"
          detailKey="card"
          itemId={supplementKey}
          onLoadingComplete={handleLoadingComplete}
        />
      )}
    </Card>
  );
}

export default Supplements;