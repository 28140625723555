import React from 'react';
import StackForm from './StackForm';
import { useParams } from 'react-router-dom';

function EditStack() {
  const { stackId } = useParams();

  return <StackForm isEditMode={true} stackId={stackId} />;
}

export default EditStack;