import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, Paper, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { collection, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import useUserProfile from '../hooks/useUserProfile';
import SupplementsTable from './SupplementsTable';
import AnalyzeWithAI from './AnalyzeWithAI';

const auth = getAuth();

function StackForm({ isEditMode, stackId }) {
  const [stackName, setStackName] = useState('');
  const [healthGoals, setHealthGoals] = useState('');
  const [notes, setNotes] = useState('');
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [healthGoalsId, setHealthGoalsId] = useState('');
  const [notesId, setNotesId] = useState('');
  const { userProfile, loading } = useUserProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (isEditMode && stackId) {
      const fetchStack = async () => {
        const docRef = doc(db, 'stacks', stackId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const stackData = docSnap.data();
          setStackName(stackData.name);
          setSelectedSupplements(stackData.supplements);

          // Fetch health goals and notes
          const healthGoalsRef = doc(db, 'healthGoals', stackData.healthGoalsRef);
          const healthGoalsSnap = await getDoc(healthGoalsRef);
          if (healthGoalsSnap.exists()) {
            setHealthGoals(healthGoalsSnap.data().goals);
            setHealthGoalsId(healthGoalsRef.id);
          }

          const notesRef = doc(db, 'notes', stackData.notesRef);
          const notesSnap = await getDoc(notesRef);
          if (notesSnap.exists()) {
            setNotes(notesSnap.data().content);
            setNotesId(notesRef.id);
          }
        } else {
          console.error('No such document!');
        }
      };

      fetchStack();
    }
  }, [isEditMode, stackId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const user = auth.currentUser;

    if (!stackName) {
      setErrorMessage('Stack name is required.');
      return;
    }

    if (user) {
      try {
        if (isEditMode) {
          // Update health goals document
          const healthGoalsRef = doc(db, 'healthGoals', healthGoalsId);
          await updateDoc(healthGoalsRef, { goals: healthGoals });

          // Update notes document
          const notesRef = doc(db, 'notes', notesId);
          await updateDoc(notesRef, { content: notes });

          // Update stack document
          const stackRef = doc(db, 'stacks', stackId);
          await updateDoc(stackRef, {
            name: stackName,
            supplements: selectedSupplements,
            updatedBy: user.displayName || user.email || 'unknown',
          });

          console.log('Stack updated successfully');
          setErrorMessage(''); // Clear error message on successful save
          navigate('/dashboard'); // Navigate to dashboard on successful update
        } else {
          // Create health goals document
          const healthGoalDocRef = await addDoc(collection(db, 'healthGoals'), {
            goals: healthGoals,
            userId: user.uid
          });

          // Create notes document
          const notesDocRef = await addDoc(collection(db, 'notes'), {
            content: notes,
            userId: user.uid
          });

          // Create stack document with references to health goals and notes
          const stack = {
            name: stackName,
            userId: user.uid,
            supplements: selectedSupplements,
            healthGoalsRef: healthGoalDocRef.id,
            notesRef: notesDocRef.id,
            origin: 'manual',
          };

          await addDoc(collection(db, 'stacks'), stack);
          navigate('/dashboard');
        }
      } catch (error) {
        setErrorMessage('Error saving stack: ' + error.message);
      }
    } else {
      setErrorMessage('No user is logged in');
    }
  };

  const handleSupplementChange = (index, field, value) => {
    const newSelectedSupplements = [...selectedSupplements];
    newSelectedSupplements[index] = { ...newSelectedSupplements[index], [field]: value };
    setSelectedSupplements(newSelectedSupplements);
  };

  const addSupplement = (newSupplementData) => {
    setSelectedSupplements([...selectedSupplements, newSupplementData]);
  };

  const removeSupplement = (index) => {
    const newSelectedSupplements = selectedSupplements.filter((_, i) => i !== index);
    setSelectedSupplements(newSelectedSupplements);
  };

  const toggleRowExpansion = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const toggleEditMode = (index) => {
    setEditMode(editMode === index ? null : index);
    if (editMode !== index) {
      setExpandedRow(index);
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container component="main" maxWidth="md">
      <Paper elevation={8} sx={{ padding: 1, marginTop: 4 }}>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5" className="main-text" >{isEditMode ? 'EDIT SUPPLEMENT STACK' : 'ADD YOUR SUPPLEMENT STACK'}</Typography>
          
          <Box sx={{ width: '100%', marginTop: 4 }}>

            <TextField
              margin="normal"
              fullWidth
              id="stackName"
              label="Stack Name (Required)"
              name="stackName"
              value={stackName}
              onChange={(e) => setStackName(e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              id="healthGoals"
              label="Health Goals (Optional)"
              name="healthGoals"
              value={healthGoals}
              onChange={(e) => setHealthGoals(e.target.value)}
            />

          </Box>

          <SupplementsTable
            selectedSupplements={selectedSupplements}
            setSelectedSupplements={setSelectedSupplements}
            expandedRow={expandedRow}
            editMode={editMode}
            toggleRowExpansion={toggleRowExpansion}
            toggleEditMode={toggleEditMode}
            handleSupplementChange={handleSupplementChange}
            removeSupplement={removeSupplement}
            addSupplement={addSupplement}
          />

<Box sx={{ width: '100%', marginTop: 2 }}>
            <AnalyzeWithAI
              healthGoals={healthGoals}
              selectedSupplements={selectedSupplements}
              userProfile={userProfile}
              onAnalysisComplete={(result) => setSelectedSupplements(result.analysis || [])}
              auth={auth}
            />
          </Box>

          <TableContainer component={Paper} sx={{ marginTop: 4 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ width: '70%' }} >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="notes"
                      label="Notes"
                      name="notes"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      InputProps={{
                        style: {
                          fontSize: '0.9rem',
                          fontWeight: 300,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: '0.9rem',
                          fontWeight: 300
                        }
                      }}
                      multiline
                      rows={2}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '50%' }}>
                  <Button variant="contained" color="primary" type="submit"  sx={{ alignSelf: 'flex-end', flexGrow: 1, marginLeft: 2 }}>
                      {isEditMode ? 'Update Stack' : 'Create Stack'}
                    </Button>
                    
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} align="left">

                    {errorMessage && (
                      <Typography color="error" variant="body2">{errorMessage}</Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

         
        </Box>
      </Paper>
    </Container>
  );
}

export default StackForm;