import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Typography, Paper, Box } from '@mui/material';
import DOMPurify from 'dompurify';
import './Detail.css';
import config from '../config';

function Detail({ dataKey, detailKey, itemId, onLoadingComplete }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [supplements, setSupplements] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [itemName, setItemName] = useState('');
  const linkedItems = useRef(new Set());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.FIREBASE_STORAGE_BASE_URL}${dataKey}%2F${itemId}.json?alt=media`);
        setSelectedItem(response.data);
        if (onLoadingComplete) {
          onLoadingComplete();
        }
      } catch (error) {
        console.error(`Error fetching ${dataKey} details:`, error);
      }
    };

    const fetchSupplements = async () => {
      try {
        const response = await axios.get(`${config.FIREBASE_STORAGE_BASE_URL}config%2Fsupplements.json?alt=media`);
        const supplementsList = response.data;
        setSupplements(supplementsList);

        if (dataKey === 'supplements') {
          const foundItem = supplementsList.find(supplement => supplement.key === itemId);
          if (foundItem) {
            setItemName(foundItem.name);
          }
        }
      } catch (error) {
        console.error('Error fetching supplements:', error);
      }
    };

    const fetchConditions = async () => {
      try {
        const response = await axios.get(`${config.FIREBASE_STORAGE_BASE_URL}config%2Fconditions.json?alt=media`);
        const conditionsList = response.data;
        setConditions(conditionsList);

        if (dataKey === 'conditions') {
          const foundItem = conditionsList.find(condition => condition.key === itemId);
          if (foundItem) {
            setItemName(foundItem.name);
          }
        }
      } catch (error) {
        console.error('Error fetching conditions:', error);
      }
    };

    fetchData();
    fetchSupplements();
    fetchConditions();
  }, [itemId, onLoadingComplete, dataKey]);

  const createLink = (name, key, type) => `<a href="/${type}/${key}">${name}</a>`;

  const addLinksToContent = (content) => {

    if (typeof content !== 'string') {
      return content; // If it's not a string, just return the content as it is.
    }
  
    let sanitizedContent = content;
    linkedItems.current.clear();

    supplements.forEach(supplement => {
      if (supplement.key !== itemId && !linkedItems.current.has(supplement.key)) {
        const regex = new RegExp(`\\b${supplement.name}\\b`, 'i'); 
        sanitizedContent = sanitizedContent.replace(regex, match => createLink(match, supplement.key, 'supplements'));
        linkedItems.current.add(supplement.key);
      }
    });

    conditions.forEach(condition => {
      if (condition.key !== itemId && !linkedItems.current.has(condition.key)) {
        const regex = new RegExp(`\\b${condition.name}\\b`, 'i'); 
        sanitizedContent = sanitizedContent.replace(regex, match => createLink(match, condition.key, 'conditions'));
        linkedItems.current.add(condition.key);
      }
    });

    return sanitizedContent;
  };

  const renderContentWithLinks = (content) => {
    if (Array.isArray(content)) {
      return (
        <ul>
          {content.map((item, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(addLinksToContent(item)) }} />
          ))}
        </ul>
      );
    }
    return <Box dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(addLinksToContent(content)) }} />;
  };

  const renderCardContent = (card) => (
    <Typography component="div">{renderContentWithLinks(card.content)}</Typography>
  );

  function FixTitle({ card, detailKey }) {
    // Convert the key to lowercase to make the comparison case-insensitive
    const title = card[detailKey];
    // Decide the text based on a case-insensitive comparison
    const textToShow = title === "Supplements that can help"
      ? "SUPPLEMENTS THAT MAY HELP"
      : title.toUpperCase();
    return (
      <Typography variant="h6" className="card-heading">
        {textToShow}
      </Typography>
    );
  }
  

  return (
    <Box 
      className="detail-component"
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center', 
        minHeight: '100vh',  // Full height of the viewport
        padding: 2 
      }}
    >
      {selectedItem ? (
        <Box 
          className="detail-content" 
          sx={{ 
            maxWidth: 1000,  // Restrict width
            width: '100%',  // Full width within the restricted maxWidth
            textAlign: 'left' 
          }}
        >
          <Typography variant="h4" className="item-heading" gutterBottom>
            {itemName}
          </Typography>
          {selectedItem.cards.map((card, index) => (
            <Paper 
              key={`${detailKey}-${index}`} 
              className="card"
              sx={{ padding: 2, marginBottom: 2 }}
            >
              <FixTitle card={card} detailKey={detailKey} />
              {renderCardContent(card)}
            </Paper>
          ))}
        </Box>
      ) : (
        <Typography>Loading {dataKey} details...</Typography>
      )}
    </Box>
  );
}

export default Detail;