import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const useUserProfile = () => {
  const [userProfile, setUserProfile] = useState({ age: '', gender: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserProfile(userDoc.data());
        }
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  return { userProfile, loading };
};

export default useUserProfile;