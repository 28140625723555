import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Paper, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

const categories = [
  { value: 'give_suggestions', label: 'Give Suggestions' },
  { value: 'report_issues', label: 'Report Issues' },
  { value: 'request_information', label: 'Request Information' },
  { value: 'other', label: 'Other' },
];

const ContactUs = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [category, setCategory] = useState('');
  const [feedback, setFeedback] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!feedback || !category) {
      setMessage('All fields are required');
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        await addDoc(collection(db, 'support'), {
          userId: user.uid,
          email: user.email,
          firstName: user.displayName?.split(' ')[0] || '',
          lastName: user.displayName?.split(' ')[1] || '',
          category,
          feedback,
          timestamp: new Date(),
        });
        setMessage('Your message has been submitted successfully');
        setFeedback('');
        setCategory('');
        setTimeout(() => {
          navigate('/');
        }, 2000); // Redirect to home after 2 seconds
      } else {
        setMessage('You need to be logged in to submit');
      }
    } catch (error) {
      setMessage(`Error submitting: ${error.message}`);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 8 }}>
        <Typography component="h1" variant="h5" gutterBottom>
          Contact Us
        </Typography>
        {message && <Typography color={message.includes('successfully') ? 'primary' : 'error'}>{message}</Typography>}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              label="Category"
            >
              {categories.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="feedback"
            label="Your Feedback"
            name="feedback"
            autoComplete="feedback"
            multiline
            rows={8}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ width: '50%' }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default ContactUs;