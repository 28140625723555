import React, { useState, useRef } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Chip,
  CircularProgress,
  Divider,
  InputAdornment,
  IconButton
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import axios from 'axios';
import useUserProfile from '../hooks/useUserProfile';
import SupplementsTable from './SupplementsTable';
import config from '../config';
import '../common.css';
import { calculateAge } from '../Utils';

const { BASE_URL } = config;

function CreateStack() {
  const [stackName, setStackName] = useState('');
  const [healthGoals, setHealthGoals] = useState('');
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [loadingAI, setLoadingAI] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showStackDetails, setShowStackDetails] = useState(false);
  const [notes, setNotes] = useState('');
  const { userProfile } = useUserProfile();
  const navigate = useNavigate();

  // Ref for scrolling to stack details
  const stackDetailsRef = useRef(null);

  const sampleGoals = [
    'Weight Management',
    'Muscle Health',
    'Digestion ',
    'Energy ',
    'Sleep Hygiene',
    'Energy ',
    'Heart Health',
    'Focus',
    'Stress Relief ',
    'Immunity',
    'GLP-1 Agonist nutrional support',
    'Healthy Hair & Skin ',
    'Healthy Aging ',
    'Memory ',
    'Joint Health',
    'Bone Health ',
    'Hormone Balance ',
    'Prostate Health ',
    'Eye Health',
    'Prenatal & Postnatal',
    'Healthy Mood',
    'Gluclose Metabolism '
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;

    if (!stackName) {
      setErrorMessage('Stack name is required.');
      return;
    }

    if (user) {
      try {
        // Create health goals document
        const healthGoalDocRef = await addDoc(collection(db, 'healthGoals'), {
          goals: healthGoals,
          userId: user.uid
        });

        // Create notes document
        const notesDocRef = await addDoc(collection(db, 'notes'), {
          content: notes,
          userId: user.uid
        });

        // Create stack document with references to health goals and notes
        const stack = {
          name: stackName,
          userId: user.uid,
          supplements: selectedSupplements,
          healthGoalsRef: healthGoalDocRef.id,
          notesRef: notesDocRef.id,
          origin: 'AI'
        };

        await addDoc(collection(db, 'stacks'), stack);
        navigate('/dashboard');
      } catch (error) {
        setErrorMessage('Error creating stack: ' + error.message);
      }
    } else {
      setErrorMessage('You need to login to create a stack.');
      navigate('/login');
    }
  };

  const MAX_REQUESTS = 3;  // Maximum number of requests allowed for non-logged-in users

  const handleGenerateStack = async () => {
    // Get the current request count from localStorage (or default to 0 if not set)
    let requestCount = parseInt(localStorage.getItem('stackRequestCount')) || 0;
  
    // Clear previous stack details
    setStackName('');
    setSelectedSupplements([]);
    setShowStackDetails(false);
    setNotes('');
  
    setLoadingAI(true);
    const auth = getAuth();
    const user = auth.currentUser;
  
    // Declare variables for stack generation
    let token = '';
    let calculatedAge = 'unknown';
    let gender = 'unknown';
  
    try {
      if (user) {
        token = await auth.currentUser.getIdToken();
        calculatedAge = calculateAge(userProfile.dateOfBirth);
        gender = userProfile.gender;
      }
      else {
        if (requestCount >= MAX_REQUESTS) {
          alert("You have reached the maximum number of stack generations. Please log in to continue.");
          navigate('/login');
          return;
        }
      }
  
      const response = await axios.post(`${BASE_URL}/generate-stack`, {
        healthGoals,
        age: calculatedAge,
        gender: gender
      }, {
        headers: {
          Authorization: `Bearer ${token}`  // This will be empty for non-logged-in users
        }
      });
  
      // Set the AI-generated stack data
      setStackName(response.data.name);
      setSelectedSupplements(response.data.supplements);
      setShowStackDetails(true);
  
      // Scroll to the stack details section
      setTimeout(() => {
        stackDetailsRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 0);
  
      // Increment the request count and store it back in localStorage
      requestCount++;
      localStorage.setItem('stackRequestCount', requestCount);
  
    } catch (error) {
      console.error('Error generating stack:', error);
    } finally {
      setLoadingAI(false);
    }
  };
  const toggleRowExpansion = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const toggleEditMode = (index) => {
    setEditMode(editMode === index ? null : index);
    if (editMode !== index) {
      setExpandedRow(index);
    }
  };

  const handleSampleGoalClick = (goal) => {
    const currentGoals = healthGoals ? healthGoals.split(',').map(g => g.trim()) : [];
    if (!currentGoals.includes(goal)) {
      const newGoals = [...currentGoals, goal].join(', ');
      setHealthGoals(newGoals);
    }
  };
 

  return (
    <Container component="main" maxWidth="md">
      <Paper elevation={8} sx={{ padding: 2, marginTop: 3, marginBottom: 3 }}>

      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 5 }}>
      <Typography component="h1" variant="h5" className="main-text">
        <span
          style={{ 
            fontFamily: 'Roboto',  // Custom font for myStack
            fontWeight: 'bold',    // Additional styling for myStack
          }}
        >
          myStack
        </span>{' '}
        AI: Create a supplement stack
      </Typography>
        <Box sx={{ width: '100%', marginTop: 1 }}>
        <TextField
            margin="normal"
            fullWidth
            id="healthGoals"
            label="What is your intent? Type or select from below"
            name="healthGoals"
            value={healthGoals}
            onChange={(e) => setHealthGoals(e.target.value)}
            InputProps={{
              style: {
                fontSize: '0.9rem',
                fontWeight: 300,
              },
              endAdornment: (
                <InputAdornment position="end">
                  {healthGoals && (
                    <IconButton
                      aria-label="clear health goals"
                      onClick={() => setHealthGoals('')}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: {
                fontSize: '0.9rem',
                fontWeight: 300,
              }
            }}
          />
        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
  <Box className="scrollable-chips" sx={{ maxHeight: 100, overflowY: 'auto', display: 'flex', gap: 1, flexWrap: 'wrap', marginTop: 1 }}>
    {sampleGoals.map((goal, index) => (
      <Chip
        key={index}
        label={goal}
        onClick={() => handleSampleGoalClick(goal)}
        clickable
        sx={{ cursor: 'pointer', margin: '0px', fontSize: '0.75rem' }}
      />
    ))}
  </Box>
  <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: 30, background: 'linear-gradient(to top, white, rgba(255, 255, 255, 0))', pointerEvents: 'none' }} />
</Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGenerateStack}
            disabled={loadingAI || !healthGoals.trim()}
            sx={{ marginTop: 2, display: 'flex', alignItems: 'center' }}
          >
            {loadingAI ? <CircularProgress size={24} color="inherit" /> : 'Generate Stack'}
          </Button>
        </Box>
        <Divider sx={{ width: '100%', margin: '24px 0' }} />
        {showStackDetails && (
          <>
            <Box ref={stackDetailsRef} sx={{ width: '100%' }}>
              <TextField
                margin="normal"
                fullWidth
                id="stackName"
                label="Stack Name* (Required)"
                name="stackName"
                value={stackName}
                onChange={(e) => setStackName(e.target.value)}
              />
              <SupplementsTable
                selectedSupplements={selectedSupplements}
                setSelectedSupplements={setSelectedSupplements}
                expandedRow={expandedRow}
                editMode={editMode}
                toggleRowExpansion={toggleRowExpansion}
                toggleEditMode={toggleEditMode}
              />
            </Box>
            <Box sx={{ width: '100%', marginTop: 2 }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ width: '70%' }}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="notes"
                          label="Notes (optional)"
                          name="notes"
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                          InputProps={{
                            style: {
                              fontSize: '0.9rem',
                              fontWeight: 300,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: '0.9rem',
                              fontWeight: 300
                            }
                          }}
                          multiline
                          rows={2}
                        />
                      </TableCell>
                      <TableCell sx={{ width: '30%' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          sx={{ alignSelf: 'flex-end', flexGrow: 1 }}
                        >
                          Create Stack
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell sx={{ width: '100%' }}>
                        {errorMessage && (
                          <Typography color="error" variant="body2">
                            {errorMessage}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
      </Box>
    </Paper>
    </Container>
  );
}

export default CreateStack;
                     