import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Accordion,
  AccordionDetails,
  Button,
  Typography,
  InputLabel,
  Box,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CreatableSelect from 'react-select/creatable';
import config from '../config';

const { BASE_URL, FIREBASE_STORAGE_BASE_URL, UNITS } = config;

const CustomAccordion = styled(Accordion)({
  boxShadow: 'none',
  border: 'none',
  '&:before': {
    display: 'none',
  },
  '&:hover': {
    cursor: 'pointer',
  },
});

const CustomAccordionSummary = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 0),
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.primary,
  textDecoration: 'underline',
  color: theme.palette.primary.main,
  '&:hover': {
    textDecoration: 'none',
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)({
  padding: '8px 16px',
});

const SupplementsTable = ({
  selectedSupplements,
  setSelectedSupplements,
  expandedRow,
  editMode,
  toggleRowExpansion,
  toggleEditMode,
}) => {
  const [supplements, setSupplements] = useState([]);
  const [supplementDetails, setSupplementDetails] = useState({});
  const [newSupplement, setNewSupplement] = useState(null);
  const [newDosage, setNewDosage] = useState('');
  const [newUnit, setNewUnit] = useState('');

  useEffect(() => {
    const fetchSupplements = async () => {
      try {
        const response = await axios.get(`${FIREBASE_STORAGE_BASE_URL}config%2Fsupplements.json?alt=media`);
        const sortedSupplements = response.data.sort((a, b) => a.name.localeCompare(b.name));
        setSupplements(sortedSupplements);
      } catch (error) {
      }
    };

    fetchSupplements();
  }, []);

  const fetchSupplementDetails = async (key) => {
    try {
      const response = await axios.get(`${FIREBASE_STORAGE_BASE_URL}supplements%2F${key}.json?alt=media`);
      console.log('Supplement details:', response.data);
      if(response.data.note) {
        return response.data.note.content;
      }
      else {
        return response.data['note:'].content
      }
    } catch (error) {

      return 'No additional information available.';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const details = {};
      for (let supplement of selectedSupplements) {
        if (!supplement.note && supplement.supplementId) {
          details[supplement.supplementId] = await fetchSupplementDetails(supplement.supplementId);
        }
      }
      setSupplementDetails(details);
    };

    fetchData();
  }, [selectedSupplements]);

  const handleAddSupplement = () => {
    if (newSupplement && newDosage && newUnit) {
      const newSupplementData = {
        supplementId: newSupplement.value,
        name: newSupplement.label,
        dosage: newDosage,
        unit: newUnit,
      };
      setSelectedSupplements((prev) => [...prev, newSupplementData]);
      setNewSupplement(null);
      setNewDosage('');
      setNewUnit('');
    }
  
  };

  const handleSupplementChange = (index, field, value) => {
    const updatedSupplements = [...selectedSupplements];
    updatedSupplements[index] = { ...updatedSupplements[index], [field]: value };
    setSelectedSupplements(updatedSupplements);
  };

  const removeSupplement = (index) => {
    const updatedSupplements = selectedSupplements.filter((_, i) => i !== index);
    setSelectedSupplements(updatedSupplements);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Supplement</TableCell>
              <TableCell align="right">Dosage</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedSupplements.length > 0 ? (
              selectedSupplements.map((supplement, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>
                      <CustomAccordion
                        expanded={expandedRow === index || editMode === index}
                        onChange={() => toggleRowExpansion(index)}
                      >
                        <CustomAccordionSummary onClick={() => toggleRowExpansion(index)}>
                          <Typography sx={{ width: '100%', flexShrink: 0, fontSize: 'inherit' }}>
                            {supplement.name}
                          </Typography>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails>
                          <Typography variant="body2">
                            {supplement.note || supplementDetails[supplement.supplementId] || 'No additional information available.'}
                            {supplement.supplementId && (
                              <a
                                href={`/supplements/${supplement.supplementId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'underline', color: '#3f51b5', marginLeft: '8px' }}
                              >
                                Read more
                              </a>
                            )}
                          </Typography>
                        </CustomAccordionDetails>
                      </CustomAccordion>
                    </TableCell>
                    <TableCell align="right">
                      {editMode === index ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <TextField
                            margin="normal"
                            label="Dosage"
                            value={supplement.dosage}
                            type="number"
                            onChange={(e) => handleSupplementChange(index, 'dosage', e.target.value)}
                            sx={{ width: 80, marginBottom: 0, marginRight: 1, '& .MuiInputBase-root': { height: 40 } }}
                            inputProps={{ style: { padding: '8px 12px', fontSize: '0.875rem' } }}
                          />
                          <FormControl sx={{ width: 105, marginBottom: 0, marginRight: 1, '& .MuiInputBase-root': { height: 40 } }}>
                            <InputLabel sx={{ color: 'grey', marginTop: 2 }}>Unit</InputLabel>
                            <Select
                              label="Unit"
                              value={supplement.unit || ""}
                              onChange={(e) => handleSupplementChange(index, 'unit', e.target.value)}
                              sx={{ fontSize: '0.875rem', marginTop: 2}}
                              inputProps={{ style: { padding: '8px 12px', fontSize: '0.875rem' } }}
                            >
                              {Object.entries(UNITS).map(([key, label]) => (
                                <MenuItem key={key} value={label} >
                                  {label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      ) : (
                        <>
                          {supplement.dosage} {supplement.unit}
                        </>
                      )}
                    </TableCell>
                    <TableCell align="right" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', borderBottom: 'none' }}>
                      {editMode === index ? (
                        <Button
                          onClick={() => toggleEditMode(index)}
                          variant="contained"
                          color="primary"
                          sx={{ height: 40, padding: '4px 12px', fontSize: '0.875rem', marginTop: 4 }}
                        >
                          Update
                        </Button>
                      ) : (
                        <>
                          <IconButton
                            aria-label="delete"
                            onClick={() => removeSupplement(index)}
                            sx={{ fontSize: 16, padding: '4px' }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              toggleEditMode(index);
                              toggleRowExpansion(index);
                            }}
                            sx={{ fontSize: 16, padding: '4px' }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography variant="body1" color="textSecondary">
                    No supplements added yet.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ width: '100%', marginTop: 2, position: 'relative', overflow: 'visible' }}>
        <Card sx={{ overflow: 'visible' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Add supplements to this stack</Typography>
            <Grid container spacing={1} alignItems="center" sx={{ overflow: 'visible', marginTop: 2 }}>
              <Grid item xs={12} sm={5} sx={{ position: 'relative', zIndex: 2 }}>
              <CreatableSelect
                  options={supplements.map(supplement => ({ label: supplement.name, value: supplement.key }))}
                  value={newSupplement}
                  onChange={(newValue) => setNewSupplement(newValue)}
                  placeholder="Supplement"
                  formatCreateLabel={(inputValue) => `${inputValue}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 200,
                      backgroundColor: '#ffFf',  // Ensure the menu background is solid white
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',  // Optional: Add shadow for better visibility
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      backgroundColor: 'opaque',  // Ensure the menu list background is solid white
                    }),
                    control: (provided) => ({
                      ...provided,
                      height: '40px',
                      backgroundColor: 'solid',  // Ensure control background is solid white
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#000',  // Ensure text color is solid and readable
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                id="newDosage"
                label="Dosage"
                name="newDosage"
                type="number"
                value={newDosage}
                onChange={(e) => setNewDosage(e.target.value)}
                sx={{ '& .MuiInputBase-root': { height: 40 }, '& .MuiInputLabel-root': { lineHeight: '1.2em' } }}
                inputProps={{ min: 0, step: 1 }} // Optional: specify min and step for more control
              />
              </Grid>
              <Grid item xs={12} sm={2} sx={{ position: 'relative', zIndex: 1 }}>
                <FormControl fullWidth  sx={{ '& .MuiInputBase-root': { height: 40 }, '& .MuiInputLabel-root': { lineHeight: '1.2em' } }}>
                  <InputLabel id="newUnit-label">Unit</InputLabel>
                  <Select
                    labelId="newUnit-label"
                    value={newUnit}
                    onChange={(e) => setNewUnit(e.target.value)}
                    label="Unit"
                  >
                    {Object.entries(UNITS).map(([key, label]) => (
                      <MenuItem key={key} value={label} >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddSupplement}
                  disabled={!newSupplement || !newDosage || !newUnit}
                  sx={{ height: 40, padding: '4px 12px', fontSize: '0.875rem' }}
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default SupplementsTable;