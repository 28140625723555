import React from 'react';
import { createRoot } from 'react-dom/client';  // Use createRoot from react-dom/client
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import { AuthProvider } from './AuthContext';
import './common.css';

const container = document.getElementById('root');
const root = createRoot(container);  // Create a root for rendering

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();