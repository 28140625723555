import { createTheme } from '@mui/material/styles';

const theme = createTheme({  
  palette: {  
    primary: {  
      main: '#185002', // Update to match the header background color  
    },  
    secondary: {  
      main: '#FFC107', // Update to match the indicator / selected tab color  
    },  
  },  
  typography: {  
    fontFamily: 'Poppins, sans-serif',  
  },  
});

export default theme;
