import React from 'react';
import { Box, Container, Typography, Link, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
    component="footer"
    sx={{
      py: 1,
      px: 0,
      mt: '0',
      backgroundColor: 'primary.main',
      color: 'white',
      textAlign: 'center',
      height: isMobile? '90px' : '40px', // Match the height of the header
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    }}
  >

    <Typography variant="body2" sx={{ display: { xs: 'block', sm: 'inline' } }}>
        <Link component={RouterLink} to="/privacy-policy" color="inherit" underline="hover" sx={{ mx: 1 }}>
          Privacy Policy
        </Link>
        {' | '}
        <Link component={RouterLink} to="/terms-of-use" color="inherit" underline="hover" sx={{ mx: 1 }}>
          Terms of Use
        </Link>
        {' | '}
        <Link component={RouterLink} to="/contact-us" color="inherit" underline="hover" sx={{ mx: 1 }}>
          Contact Us
        </Link>
        {' | '}

        {' © '}
        {new Date().getFullYear()} myStack
      </Typography>

  </Box>
  );
};

export default Footer;