import React from 'react';
import { Box, Button, Typography, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import heroImage from '../assets/images/hero.png';

export default function LandingPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        background: 'linear-gradient(135deg, #e8f1e8 0%, #174f04 100%)',
        padding: isMobile ? '0px' : '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
      }}
    >
      <Grid container sx={{ maxWidth: '100%', height: '100%' }}>
        {/* Left Text Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'left',
            padding: isMobile ? '10px' : '40px',
            boxSizing: 'border-box',
          }}
        >
          <Box>
            <Typography
              variant={isMobile ? 'h4' : 'h3'}
              sx={{
                fontWeight: 'bold',
                lineHeight: 1.2,
                letterSpacing: '0.5px',
                color: theme.palette.text.primary,
              }}
              gutterBottom
            >
              Achieve your health goals with AI optimized supplement stacks
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.text.secondary,
                marginBottom: '30px',
              }}
            >
              Learn, create, analyze, and share your stacks using myStack.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              href="/home"
              sx={{
                padding: '12px 24px',
                fontSize: '16px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                background: 'linear-gradient(45deg, #388e3c, #4caf50)',
                '&:hover': {
                  background: 'linear-gradient(45deg, #4caf50, #388e3c)',
                },
              }}
            >
              Get Started
            </Button>
          </Box>
        </Grid>

        {/* Right Image Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: isMobile ? '20px' : '0 40px',
            boxSizing: 'border-box',
          }}
        >
          <Box sx={{ maxWidth: isMobile ? '100%' : '100%' }}>
            <img
              src={heroImage}
              alt="Hero"
              style={{
                width: '100%',
                maxWidth: '500px', // Adjust the maximum size
                height: 'auto',
                borderRadius: '12px',
                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}